import config, { subapps } from "./config";

const basicSubapps = ["amss-authorize", "amss-sysconsole"];
const amss0Subapps = ["amss-actualdatas", "amss-finaldatas"];
const amss1Subapps = ["amss-backstage", "amss-workstage", "amss-workbench", "amss-vsreports", "amss-screenshots"];
const amss3Subapps = ["amss-remixbudget", "amss-remixforecast", "amss-remixstage", "amss-remixmonitor", "amss-remixrenovate", "amss-capitaldatas"];
const amss5Subapps = ["amss-reformdatas", "amss-portfolio"];
const kaideSubapps = ["amss-batchwise", "amss-mixbudget", "amss-capitaldatas"];
const fosunSubapps = ["amss-accredition", "amss-fosundatas"];

const otherSubapps = config.APP_OTHERSUB.split(",")
  .filter((item) => item !== "")
  .map((item) => item.trim());

const getVaildSubapp = (customer) => {
  let subapps = [];

  if (customer === "fosun" || customer === "fosunhive") {
    subapps = [...basicSubapps, ...amss0Subapps, ...amss1Subapps, ...amss5Subapps, ...fosunSubapps];
  } else if (customer === "capitaland") {
    subapps = [...basicSubapps, ...amss0Subapps, ...amss3Subapps, ...kaideSubapps];
  } else if (customer === "kaide") {
    subapps = [...basicSubapps, ...amss0Subapps, ...amss3Subapps, "amss-capitaldatas"];
  } else if (customer === "vanke") {
    subapps = [...basicSubapps, ...amss0Subapps, ...amss3Subapps];
  } else if (customer === "amss3") {
    subapps = [...basicSubapps, ...amss0Subapps, ...amss3Subapps];
  } else if (customer === "amss1") {
    subapps = [...basicSubapps, ...amss0Subapps, ...amss1Subapps];
  } else if (customer === "anshi") {
    if (config.APP_SUBGROUP === "remixbudget") {
      subapps = [...basicSubapps, ...amss0Subapps, "amss-remixbudget", "amss-remixforecast", "amss-remixstage", "amss-remixmonitor", "amss-remixrenovate"];
    } else {
      subapps = [...basicSubapps, ...amss0Subapps, ...amss1Subapps, ...amss3Subapps];
    }
  } else if (customer === "bridge5") {
    subapps = [...basicSubapps, ...amss0Subapps, ...amss1Subapps, ...amss3Subapps, ...amss5Subapps];
  } else {
    subapps = [...basicSubapps, ...amss0Subapps];
  }

  return [...subapps, ...otherSubapps];
};

const vaildSubapps = subapps
  .filter((item) => getVaildSubapp(config.APP_CUSTOMER)?.includes(item?.name))
  .map((item) => {
    return {
      ...item,
      container: "#subapp",
      props: {
        routerBase: item.activeRule,
      },
    };
  });

export const vaildSubappNames = vaildSubapps?.map((item) => item.name);

export default vaildSubapps;
