import pkg from "../package.json";

declare const window: any;

export const config: Record<string, string | boolean> = {
  APP_VERSION: pkg.version || "0.0.0",
  APP_SUBNAME: pkg.name || "",
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT || window?.__INITIAL_CONFIG__?.API_ENDPOINT || "",
  APP_ENDPOINT: process.env.REACT_APP_APP_ENDPOINT || `//${window.location.origin.split("//")?.[1]}`,
  APP_CUSTOMER: process.env.REACT_APP_APP_CUSTOMER || window?.__INITIAL_CONFIG__?.APP_CUSTOMER || "bridge5",
  B5A_PRIORITY: process.env.REACT_APP_B5A_PRIORITY || window?.__INITIAL_CONFIG__?.B5A_PRIORITY || false,
  HIDE_MODULES: process.env.REACT_APP_HIDE_MODULES || window?.__INITIAL_CONFIG__?.HIDE_MODULES || "",
  APP_SUBGROUP: process.env.REACT_APP_APP_SUBGROUP || window?.__INITIAL_CONFIG__?.APP_SUBGROUP || "",
  APP_OTHERSUB: process.env.REACT_APP_APP_OTHERSUB || window?.__INITIAL_CONFIG__?.APP_OTHERSUB || "",
};

export const app_endpoint: Record<string, string> = {
  accredition:
    process.env.REACT_APP_SUBAPP_ACCREDITION ||
    window?.__INITIAL_CONFIG__?.SUBAPP_ACCREDITION ||
    `${config.APP_ENDPOINT}/subapp/accredition/`,
  actualdatas:
    process.env.REACT_APP_SUBAPP_ACTUALDATAS ||
    window?.__INITIAL_CONFIG__?.SUBAPP_ACTUALDATAS ||
    `${config.APP_ENDPOINT}/subapp/actualdatas/`,
  authorize:
    process.env.REACT_APP_SUBAPP_AUTHORIZE ||
    window?.__INITIAL_CONFIG__?.SUBAPP_AUTHORIZE ||
    `${config.APP_ENDPOINT}/subapp/authorize/`,
  boilerplate:
    process.env.REACT_APP_SUBAPP_BOILERPLATE ||
    window?.__INITIAL_CONFIG__?.SUBAPP_BOILERPLATE ||
    `${config.APP_ENDPOINT}/subapp/boilerplate/`,
  backstage:
    process.env.REACT_APP_SUBAPP_BACKSTAEG ||
    window?.__INITIAL_CONFIG__?.SUBAPP_BACKSTAEG ||
    `${config.APP_ENDPOINT}/subapp/backstage/`,
  batchwise:
    process.env.REACT_APP_SUBAPP_BATCHWISE ||
    window?.__INITIAL_CONFIG__?.SUBAPP_BATCHWISE ||
    `${config.APP_ENDPOINT}/subapp/batchwise/`,
  capitaldatas:
    process.env.REACT_APP_SUBAPP_CAPITALDATAS ||
    window?.__INITIAL_CONFIG__?.SUBAPP_CAPITALDATAS ||
    `${config.APP_ENDPOINT}/subapp/capitaldatas/`,
  citymaps:
    process.env.REACT_APP_SUBAPP_CITYMAPS ||
    window?.__INITIAL_CONFIG__?.SUBAPP_CITYMAPS ||
    `${config.APP_ENDPOINT}/subapp/citymaps/`,
  fsreports:
    process.env.REACT_APP_SUBAPP_FSREPORTS ||
    window?.__INITIAL_CONFIG__?.SUBAPP_FSREPORTS ||
    `${config.APP_ENDPOINT}/subapp/fsreports/`,
  finaldatas:
    process.env.REACT_APP_SUBAPP_FINALDATAS ||
    window?.__INITIAL_CONFIG__?.SUBAPP_FINALDATAS ||
    `${config.APP_ENDPOINT}/subapp/finaldatas/`,
  fosundatas:
    process.env.REACT_APP_SUBAPP_FOSUNDATAS ||
    window?.__INITIAL_CONFIG__?.SUBAPP_FOSUNDATAS ||
    `${config.APP_ENDPOINT}/subapp/fosundatas/`,
  nonstandard:
    process.env.REACT_APP_SUBAPP_NONSTANDARD ||
    window?.__INITIAL_CONFIG__?.SUBAPP_NONSTANDARD ||
    `${config.APP_ENDPOINT}/subapp/nonstandard/`,
  portfolio:
    process.env.REACT_APP_SUBAPP_PROTFOLIO ||
    window?.__INITIAL_CONFIG__?.SUBAPP_PROTFOLIO ||
    `${config.APP_ENDPOINT}/subapp/portfolio/`,
  quicksearch:
    process.env.REACT_APP_SUBAPP_QUICKSEARCH ||
    window?.__INITIAL_CONFIG__?.SUBAPP_QUICKSEARCH ||
    `${config.APP_ENDPOINT}/subapp/quicksearch/`,
  reformdatas:
    process.env.REACT_APP_SUBAPP_REFORMDATAS ||
    window?.__INITIAL_CONFIG__?.SUBAPP_REFORMDATAS ||
    `${config.APP_ENDPOINT}/subapp/reformdatas/`,
  remixbudget:
    process.env.REACT_APP_SUBAPP_REMIXBUDGET ||
    window?.__INITIAL_CONFIG__?.SUBAPP_REMIXBUDGET ||
    `${config.APP_ENDPOINT}/subapp/remixbudget/`,
  remixforecast:
    process.env.REACT_APP_SUBAPP_REMIXFORECAST ||
    window?.__INITIAL_CONFIG__?.SUBAPP_REMIXFORECAST ||
    `${config.APP_ENDPOINT}/subapp/remixforecast/`,
  remixmonitor:
    process.env.REACT_APP_SUBAPP_REMIXMONITOR ||
    window?.__INITIAL_CONFIG__?.SUBAPP_REMIXMONITOR ||
    `${config.APP_ENDPOINT}/subapp/remixmonitor/`,
  remixrenovate:
    process.env.REACT_APP_SUBAPP_REMIXRENOVATE ||
    window?.__INITIAL_CONFIG__?.SUBAPP_REMIXRENOVATE ||
    `${config.APP_ENDPOINT}/subapp/remixrenovate/`,
  remixstage:
    process.env.REACT_APP_SUBAPP_REMIXSTAGE ||
    window?.__INITIAL_CONFIG__?.SUBAPP_REMIXSTAGE ||
    `${config.APP_ENDPOINT}/subapp/remixstage/`,
  reportset:
    process.env.REACT_APP_SUBAPP_REPORTSET ||
    window?.__INITIAL_CONFIG__?.SUBAPP_REPORTSET ||
    `${config.APP_ENDPOINT}/subapp/reportset/`,
  reportstage:
    process.env.REACT_APP_SUBAPP_REPORTSTAGE ||
    window?.__INITIAL_CONFIG__?.SUBAPP_REPORTSTAGE ||
    `${config.APP_ENDPOINT}/subapp/reportstage/`,
  reportstyles:
    process.env.REACT_APP_SUBAPP_REPORTSTYLES ||
    window?.__INITIAL_CONFIG__?.SUBAPP_REPORTSTYLES ||
    `${config.APP_ENDPOINT}/subapp/reportstyles/`,
  screenshots:
    process.env.REACT_APP_SUBAPP_SCREENSHOTS ||
    window?.__INITIAL_CONFIG__?.SUBAPP_SCREENSHOTS ||
    `${config.APP_ENDPOINT}/subapp/screenshots/`,
  sysconsole:
    process.env.REACT_APP_SUBAPP_SYSCONSOLE ||
    window?.__INITIAL_CONFIG__?.SUBAPP_SYSCONSOLE ||
    `${config.APP_ENDPOINT}/subapp/sysconsole/`,
  valuations:
    process.env.REACT_APP_SUBAPP_VALUATIONS ||
    window?.__INITIAL_CONFIG__?.SUBAPP_VALUATIONS ||
    `${config.APP_ENDPOINT}/subapp/valuations/`,
  vsreports:
    process.env.REACT_APP_SUBAPP_VSREPORTS ||
    window?.__INITIAL_CONFIG__?.SUBAPP_VSREPORTS ||
    `${config.APP_ENDPOINT}/subapp/vsreports/`,
  workstage:
    process.env.REACT_APP_SUBAPP_WORKSTAGE ||
    window?.__INITIAL_CONFIG__?.SUBAPP_WORKSTAGE ||
    `${config.APP_ENDPOINT}/subapp/workstage/`,
  workbench:
    process.env.REACT_APP_SUBAPP_WORKBENCH ||
    window?.__INITIAL_CONFIG__?.SUBAPP_WORKBENCH ||
    `${config.APP_ENDPOINT}/subapp/workbench/`,
  mixbudget:
    process.env.REACT_APP_SUBAPP_MIXBUDGET ||
    window?.__INITIAL_CONFIG__?.SUBAPP_MIXBUDGET ||
    `${config.APP_ENDPOINT}/subapp/mixbudget/`,
};

export const subapps: Array<Record<string, string>> = [
  {
    name: "amss-accredition",
    entry: app_endpoint.accredition,
    activeRule: "/accredition",
  },
  {
    name: "amss-actualdatas",
    entry: app_endpoint.actualdatas,
    activeRule: "/actualdatas",
  },
  {
    name: "amss-authorize",
    entry: app_endpoint.authorize,
    activeRule: "/authorize",
  },
  {
    name: "amss-boilerplate",
    entry: app_endpoint.boilerplate,
    activeRule: "/boilerplate",
    container: "#subapp",
  },
  {
    name: "amss-backstage",
    entry: app_endpoint.backstage,
    activeRule: "/backstage",
  },
  {
    name: "amss-batchwise",
    entry: app_endpoint.batchwise,
    activeRule: "/batchwise",
  },
  {
    name: "amss-capitaldatas",
    entry: app_endpoint.capitaldatas,
    activeRule: "/capitaldatas",
  },
  {
    name: "amss-citymaps",
    entry: app_endpoint.citymaps,
    activeRule: "/citymaps",
  },
  {
    name: "amss-fsreports",
    entry: app_endpoint.fsreports,
    activeRule: "/fsreports",
  },
  {
    name: "amss-finaldatas",
    entry: app_endpoint.finaldatas,
    activeRule: "/finaldatas",
  },
  {
    name: "amss-fosundatas",
    entry: app_endpoint.fosundatas,
    activeRule: "/fosundatas",
  },
  {
    name: "amss-nonstandard",
    entry: app_endpoint.nonstandard,
    activeRule: "/nonstandard",
  },
  {
    name: "amss-portfolio",
    entry: app_endpoint.portfolio,
    activeRule: "/portfolio",
  },
  {
    name: "amss-quicksearch",
    entry: app_endpoint.quicksearch,
    activeRule: "/quicksearch",
  },
  {
    name: "amss-reformdatas",
    entry: app_endpoint.reformdatas,
    activeRule: "/reformdatas",
  },
  {
    name: "amss-remixbudget",
    entry: app_endpoint.remixbudget,
    activeRule: "/remixbudget",
  },
  {
    name: "amss-remixforecast",
    entry: app_endpoint.remixforecast,
    activeRule: "/remixforecast",
  },
  {
    name: "amss-remixmonitor",
    entry: app_endpoint.remixmonitor,
    activeRule: "/remixmonitor",
  },
  {
    name: "amss-remixrenovate",
    entry: app_endpoint.remixrenovate,
    activeRule: "/remixrenovate",
  },
  {
    name: "amss-remixstage",
    entry: app_endpoint.remixstage,
    activeRule: "/remixstage",
  },
  {
    name: "amss-reportset",
    entry: app_endpoint.reportset,
    activeRule: "/reportset",
  },
  {
    name: "amss-reportstage",
    entry: app_endpoint.reportstage,
    activeRule: "/reportstage",
  },
  {
    name: "amss-reportstyles",
    entry: app_endpoint.reportstyles,
    activeRule: "/reportstyles",
  },
  {
    name: "amss-screenshots",
    entry: app_endpoint.screenshots,
    activeRule: "/screenshots",
  },
  {
    name: "amss-sysconsole",
    entry: app_endpoint.sysconsole,
    activeRule: "/sysconsole",
  },
  {
    name: "amss-valuations",
    entry: app_endpoint.valuations,
    activeRule: "/valuations",
  },
  {
    name: "amss-vsreports",
    entry: app_endpoint.vsreports,
    activeRule: "/vsreports",
  },
  {
    name: "amss-workbench",
    entry: app_endpoint.workbench,
    activeRule: "/workbench",
  },
  {
    name: "amss-workstage",
    entry: app_endpoint.workstage,
    activeRule: "/workstage",
  },
];

export default config;
