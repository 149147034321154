import { Subject } from "rxjs";
import { filter } from "rxjs/operators";

const alertSubject = new Subject();

const defaultId = "default-alert";

export const AlertType = {
  Success: "Success",
  Error: "Error",
  Info: "Info",
  Warning: "Warning",
};

export function alert(alt) {
  alt.id = alt.id || defaultId;
  alertSubject.next(alt);
}

export function clear(id = defaultId) {
  alertSubject.next({ id });
}

export function onAlert(id = defaultId) {
  return alertSubject.asObservable().pipe(filter(x => x && x.id === id));
}

export function success(message, options) {
  alert({ ...options, type: AlertType.Success, message });
}

export function error(message, options) {
  alert({ ...options, type: AlertType.Error, message });
}

export function info(message, options) {
  alert({ ...options, type: AlertType.Info, message });
}

export function warn(message, options) {
  alert({ ...options, type: AlertType.Warning, message });
}
