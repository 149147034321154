import React, { useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useMutation } from "react-query";
import { RemoveScroll } from "react-remove-scroll";
import { checkIsAuthenticated, getCurrentUser } from "@bridge5/helpers";
import { CommonHeader } from "@bridge5/components";
import { Box } from "@bridge5/elements";

import { AuthService, MineService } from "#/apis/accounts";

const CommonSubapp = ({
  header = null,
  children = null,
  headerHeight = "56px",
}) => {
  return (
    <Box className="subapp-body">
      {header && (
        <Box as="header" className={RemoveScroll.classNames.fullWidth}>
          {header}
        </Box>
      )}
      <Box>{children}</Box>
    </Box>
  );
};

export const ApplicationLayout = ({ children = null }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentSubapp = location.pathname.split("/")?.[1]?.toLowerCase();
  const hideHeaderApp = ["workbench", "portfolio", "mixbudget"];
  const loginPathname = "/authorize/login";
  const redeemPath = location.pathname.split("/")?.[2]?.toLowerCase();
  const isRedeem = redeemPath === "redeem";

  const hideHeader =
    location.pathname === "/login" ||
    location.pathname === "/authorize/login" ||
    hideHeaderApp.includes(currentSubapp);

  const isAuthenticated = checkIsAuthenticated();

  useEffect(() => {
    !isAuthenticated && !isRedeem && navigate(loginPathname);
  }, [isAuthenticated, loginPathname, isRedeem, navigate]);

  const currentUser = getCurrentUser();

  // const { requestErrors, setRequestErrors } = useContext(ApplicationContext);

  const { mutate: mutateChangePwd } = useMutation(MineService.changePwd, {
    onSuccess: (data, variables, context) => {
      // if (data?.errors) {
      //   setRequestErrors(data.errors);
      // } else {
      //   setRequestErrors({});
      // }
    },
  });

  const { mutate: mutateLogout } = useMutation(AuthService.logout, {
    onSuccess: (data, variables, context) => {
      navigate(loginPathname);
    },
  });

  return (
    <CommonSubapp
      header={
        !hideHeader && (
          <CommonHeader
            currentUser={currentUser}
            // requestErrors={requestErrors}
            onLogoutClick={() => mutateLogout()}
            onChangePwdSubmit={(values) => mutateChangePwd(values)}
            // onLocaleChange={values => setLocale(values === "zh" ? "en" : "zh")}
            onGobackClick={() => navigate("/authorize")}
          />
        )
      }
    >
      <Outlet />
    </CommonSubapp>
  );
};
