import { request, alertKeys } from "#/commons";

export const changePwd = async payload => {
  try {
    const data = await request.post(`/accounts/mine/change-password`, payload);

    if (data?.errors === undefined) {
      alertKeys.success("密码修改成功");
    }
    return data;
  } catch (err) {
    console.log(err);
  }
};
