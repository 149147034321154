import "nprogress/nprogress.css";
import nprogress from "nprogress";
import { createRoot } from "react-dom/client";
import { registerMicroApps, runAfterFirstMounted, setDefaultMountApp, start } from "qiankun";
import reportWebVitals from "./reportWebVitals";
import renderApplication from "./renderApplication";
import * as serviceWorker from "./serviceWorker";
import vaildSubapps from "./subapps";

nprogress.configure({ showSpinner: false });

registerMicroApps(vaildSubapps, {
  beforeLoad: [
    app => {
      nprogress.start();
      console.log("[LifeCycle] before load %c%s", "color: green;", app.name);
      return Promise.resolve();
    },
  ],
  beforeMount: [
    app => {
      nprogress.done();
      console.log("[LifeCycle] before mount %c%s", "color: green;", app.name);
      return Promise.resolve();
    },
  ],
  afterUnmount: [
    app => {
      console.log("[LifeCycle] after unmount %c%s", "color: green;", app.name);
    },
  ],
});

setDefaultMountApp("/authorize/login");

start();

const container = document.getElementById("mainapp");
const root = createRoot(container);

renderApplication(root);

runAfterFirstMounted(() => {
  reportWebVitals();
});

serviceWorker.unregister();
