import enLocaleData from "@bridge5/locales/languages/en.json";
import zhLocaleData from "@bridge5/locales/languages/zh.json";
import enSelfLocaleData from "../languages/en.json";
import zhSelfLocaleData from "../languages/zh.json";

export const DEFAULT_LOCALE = "zh";

export const appLocales = ["en", "zh"];

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE ? formatTranslationMessages(DEFAULT_LOCALE, enSelfLocaleData) : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE ? defaultFormattedMessages[key] : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

export const translationMessages = {
  en: formatTranslationMessages("en", { ...enLocaleData, ...enSelfLocaleData }),
  zh: formatTranslationMessages("zh", { ...zhLocaleData, ...zhSelfLocaleData }),
};

export default translationMessages;
