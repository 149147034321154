import React from "react";
import { consola } from "consola/browser";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClientProvider, QueryClient } from "react-query";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

import { ApplicationLayout } from "./layouts";
import { setGlobalStyles } from "./styles";
import { vaildSubappNames } from "./subapps";
import config from "./config";

import HomePage from "./pages/home/loadable";

const queryClient = new QueryClient();

const renderApplication = root => {
  setGlobalStyles();

  consola.info(`version : ${config?.APP_SUBNAME} ${config?.APP_VERSION}`);
  consola.info(`customer: ${config?.APP_CUSTOMER}`);
  consola.info(`endpoint: ${config?.API_ENDPOINT}`);
  consola.info(`subapps : ${vaildSubappNames}`);

  root.render(
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route element={<ApplicationLayout />}>
            <Route index element={<HomePage />} />
            <Route path="*" element={<div id="subapp" />} />
          </Route>
        </Routes>
      </Router>
      {process.env.NODE_ENV !== "production" && <ReactQueryDevtools position="bottom-right" />}
    </QueryClientProvider>
  );
};

export default renderApplication;
